import React, { Component } from 'react'

import posed, { PoseGroup } from 'react-pose'

import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'

import PrevNextNav from '../PrevNextNav'

import './style.scss'

export default class ArticleSlider extends Component {
  constructor(props) {
    super(props)
    this.state = { currentIndex: 0 }
  }

  updateIndex(index) {
    if (index < 0) index = this.maxIndex - 1
    if (index >= this.maxIndex) index = 0
    this.setState({ currentIndex: index })
  }

  handleNext = () => {
    this.updateIndex(this.state.currentIndex + 1)
  }

  handlePrevious = () => {
    this.updateIndex(this.state.currentIndex - 1)
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            blog_articles: allMdx(
              filter: { frontmatter: { type: { eq: "blog" } } }
              sort: { order: DESC, fields: frontmatter___date }
              limit: 3
            ) {
              edges {
                node {
                  id
                  fields {
                    slug
                  }

                  excerpt
                  frontmatter {
                    category
                    date(formatString: "D MMMM YYYY")
                    title
                    thumbnail_image {
                      childImageSharp {
                        fluid(maxWidth: 642, maxHeight: 320) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={({ blog_articles }) => {
          this.maxIndex = blog_articles.edges.length
          const article = blog_articles.edges[this.state.currentIndex]

          return (
            <section className="my-3 mb-5 my-sm-5">
              <div className="container">
                <div className="p-3 pb-sm-5 bg-white shadow position-relative">
                  <div className="c-blog-slider-nav">
                    <div className="c-blog-slider-nav__pager">
                      <PrevNextNav
                        nextHandler={this.handleNext}
                        prevHandler={this.handlePrevious}
                      />
                    </div>
                  </div>
                  <Article key={this.state.currentIndex} data={article} />
                </div>
              </div>
            </section>
          )
        }}
      />
    )
  }
}

const Article = ({ data }) => {
  const {
    id,
    fields: { slug },
    frontmatter: { title, date, thumbnail_image, category },
  } = data.node

  return (
    <PoseGroup animateOnMount={true}>
      <PosedArticle key={Math.random() * 1000 + data.id}>
        <article className="c-large-article-teaser">
          <div className="row">
            <Col className="col-sm-6">
              <Link to={slug}>
                <Img
                  className="d-block w-100"
                  fluid={thumbnail_image.childImageSharp.fluid}
                  alt={data.title}
                />
              </Link>
            </Col>
            <Col className="col-sm-6 px-sm-4 py-3 pt-md-3 d-flex">
              <div className="my-auto">
                <div className="c-large-article-teaser__meta">{category}</div>
                <div className="c-large-article-teaser__meta text-mid">
                  {date}
                </div>
                <a href={slug}>
                  <h2 className="c-large-article-teaser__title my-3 text-dark">
                    {title}
                  </h2>
                </a>
                <Link to={slug} className="c-read-more" target="_blank">
                  Read post
                </Link>
              </div>
            </Col>
          </div>
        </article>
      </PosedArticle>
    </PoseGroup>
  )
}

const PosedArticle = posed.div({
  enter: { staggerChildren: 100 },
  exit: {},
})

const Col = posed.div({
  enter: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 50 },
})
