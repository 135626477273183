import React, { Component } from 'react'
import MediaQuery from 'react-responsive'
import scrollToComponent from 'react-scroll-to-component'

import './style.scss'

import Questions from './Questions'
import Interest from './Interest'

const mobile = '(max-width: 767px)'
const desk = '(min-width: 768px)'

export default class InterestsOverview extends Component {
  constructor(props) {
    super(props)
    const { interests } = props

    const activeInterestTitle = interests[0].title
    const filteredQuestions = this.filterQuestions(activeInterestTitle, 0)

    this.state = {
      activeInterest: activeInterestTitle,
      currentIndex: 0,
      maxIndex: filteredQuestions.maxIndex,
      filteredQuestions: filteredQuestions.questions,
      forceClosed: true,
      isMobile: false,
    }

    this.scrollTo = this.scrollTo.bind(this)
  }

  filterQuestions = (interestTitle, currentIndex) => {
    const maxInterests = 6
    const filteredQuestions = Object.assign({}, this.props.questions)

    filteredQuestions.edges = filteredQuestions.edges.filter(edge => {
      return edge.node.frontmatter.interest_areas.indexOf(interestTitle) > -1
    })

    const maxIndex =
      Math.ceil(filteredQuestions.edges.length / maxInterests) - 1

    // pagination
    filteredQuestions.edges = filteredQuestions.edges.slice(
      maxInterests * currentIndex,
      maxInterests * currentIndex + maxInterests
    )

    return {
      questions: filteredQuestions,
      maxIndex: maxIndex,
    }
  }

  scrollTo(item) {
    window.matchMedia(mobile).matches &&
      scrollToComponent(item, {
        offset: -20,
        align: 'top',
        duration: 500,
      })
  }

  handleInterestClick(interest) {
    const filteredQuestions = this.filterQuestions(interest.title, 0)

    this.setState(
      {
        filteredQuestions: filteredQuestions.questions,
        maxIndex: filteredQuestions.maxIndex,
        currentIndex: 0,
        activeInterest: interest.title,
        forceClosed:
          !this.state.forceClosed &&
          this.state.activeInterest === interest.title,
      },
      () => {
        this.scrollTo(this[interest.title])
      }
    )
  }

  changeIndex = newIndex => {
    const filteredQuestions = this.filterQuestions(
      this.state.activeInterest,
      newIndex
    )
    this.setState({
      currentIndex: newIndex,
      filteredQuestions: filteredQuestions.questions,
      maxIndex: filteredQuestions.maxIndex,
    })
  }

  previousInterests = () => {
    this.changeIndex(this.state.currentIndex - 1)
  }

  nextInterests = () => {
    this.changeIndex(this.state.currentIndex + 1)
  }

  handleResize = () => {
    this.setState({ isMobile: window.matchMedia(mobile).matches })
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleResize)
  }

  render() {
    const { interests } = this.props

    // moved the questions to a seperate component
    const questions =
      this.state.isMobile && this.state.forceClosed ? (
        ''
      ) : (
        <Questions
          state={this.state}
          nextHandler={this.nextInterests}
          previousHandler={this.previousInterests}
        />
      )

    return (
      <div className="container my-3 my-md-5">
        <div className="row">
          <div className="col-12 col-lg-4 pl-4">
            <div className="c-mini-title">Discover what we can do for you</div>
            <h2 className="c-section-title">Choose your area of interest</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 pr-md-3 pr-lg-5 mb-md-3">
            <div className="row no-gutters c-interest-group shadow">
              {interests.map(interest => (
                <div
                  key={interest.title}
                  className="col-lg-6"
                  ref={interestRef => (this[interest.title] = interestRef)}
                >
                  <Interest
                    isActive={
                      !(this.state.isMobile && this.state.forceClosed) &&
                      this.state.activeInterest === interest.title
                    }
                    onClick={() => this.handleInterestClick(interest)}
                    data={interest}
                  />
                  <MediaQuery query={mobile}>
                    {interest.title === this.state.activeInterest && questions}
                  </MediaQuery>
                </div>
              ))}
            </div>
          </div>
          <MediaQuery query={desk}>{questions}</MediaQuery>
        </div>
      </div>
    )
  }
}
