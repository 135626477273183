import React, { Component } from 'react'

import PrevNextNav from '../PrevNextNav'
import Equalizer from 'react-equalizer'

import './style.scss'

// flickity implementation slightly convoluted to prevent prerender issues
export default class CardSlider extends Component {
  constructor(props) {
    super(props)

    this.state = { Flickity: null }
    if (typeof window !== 'undefined') {
      const Flickity = require('react-flickity-component')
      this.state.Flickity = Flickity
    }

    this.getCards = this.getCards.bind(this)
  }

  componentDidMount() {
    // force correct rendering after mount
    setTimeout(() => {
      if (this.flkty) this.flkty.resize()
    }, 1000)
  }

  flcktyNext = () => {
    this.flkty.next()
  }

  flcktyPrevious = () => {
    this.flkty.previous()
  }

  getCards = length => {
    const cards = Array(length)
      .fill(undefined)
      .map((item, index) => this[`card${index}`])

    return cards
  }

  render() {
    const { Flickity } = this.state
    const flickityOptions = {
      cellAlign: 'left',
      contain: true,
      groupCells: true,
      pageDots: false,
      prevNextButtons: false,
      imagesLoaded: true,
    }

    const { subTitle, title, body, cards } = this.props.data

    return (
      <div className="bg-light">
        <section className="container px-3 p-lg-0 position-relative">
          <div className="pb-0 pt-3 pb-sm-3 py-md-5">
            <div className="row align-center">
              <div className="col col-lg-6 col-xl-4 px-lg-4">
                <div className="c-mini-title">{subTitle}</div>
                <h2 className="c-section-title">{title}</h2>
                <p>{body}</p>
              </div>
              <div className="col-lg-6 col-xl-8 px-0 pt-2 pt-sm-4 position-static">
                {Flickity && (
                  <div>
                    <Equalizer nodes={() => this.getCards(cards.length)}>
                      <Flickity
                        flickityRef={c => (this.flkty = c)}
                        className={'c-card-carousel'} // default ''
                        elementType={'div'} // default 'div'
                        options={flickityOptions} // takes flickity options {}
                        disableImagesLoaded={false} // default false
                        reloadOnUpdate // default false
                      >
                        {cards.map((card, i) => (
                          <div className="col-sm-6 col px-1" key={card.title}>
                            <div
                              ref={r => (this[`card${i}`] = r)}
                              className="c-card shadow py-3 px-3 px-lg-4 py-lg-4 px-xxl-5"
                              style={{ backgroundColor: card.color }}
                            >
                              <div className="c-card__content">
                                <div className="c-sober-mini-title c-card-type">
                                  {card.type}
                                </div>
                                <a href={card.link}>
                                  <h2 className="c-card-title-product">
                                    {card.title}
                                  </h2>
                                </a>
                              </div>
                              <footer className="mt-2" />
                              <div className="c-product-home-card__image-wrap">
                                <img
                                  src={card.image.childImageSharp ? card.image.childImageSharp.original.src: card.image.publicURL}
                                  alt={card.title}
                                  className="c-product-home-card__image"
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </Flickity>
                    </Equalizer>
                    <div className="c-card-carousel-nav mt-3 mt-sm-0">
                      <PrevNextNav
                        nextHandler={this.flcktyNext}
                        prevHandler={this.flcktyPrevious}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
//<a href={card.link} className="c-read-more">
//  Read more
//</a>
