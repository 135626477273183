import React, { Component } from 'react'
import DataStoriesLaptop from '../DataStoriesLaptop'

import './style.scss'


export default class DataStoriesOldHome extends Component {
  constructor(props) {
    super(props)
  }

  state = {
  }

  render() {
    return (
        <div className="c-ds-home-wrapper">
            <section
                className="c-ds-home"
            >
                <article
        className="c-ds-home__content py-3 py-md-5 text-white my-auto pr-3 pr-sm-0"
      >

                <div
                    className="c-ds-home__content container position-relative pt-5 pb-5 pb-sm-3  d-flex flex-column h-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                <div className="c-ds-home-slide-content__text">
                                    <span className="c-ds-home-slide-content__lightblue">Understand</span> what really
                                    drives your <span className="c-ds-home-slide-content__lightblue">KPIs</span>
                                </div>
                                <div className="c-ds-home-slide-content__subtext">
                                    Award winning AI-based augmented analytics platform to make your KPI optimization efforts painless, scalable and systematic.
                                </div>

                            </div>
                            <div className="col-md-5 col-lg-5 d-none d-md-block">
                                <DataStoriesLaptop />
                            </div>
                        </div>
                    </div>
                </div>
                </article>

            </section>
        </div>
    )
  }
}

/*

               <div className="item">
                    <img src="{{ static('images/products/home/screenshot-platform_whatifs.png') }}"
                         className="img-responsive" alt="" title=""/>
                </div>
                <div className="item">
                    <img src="{{ static('images/products/home/screenshot-kpi.png') }}"
                         className="img-responsive" alt="" title=""/>
                </div>
                <div className="item">
                    <img src="{{ static('images/products/home/screenshot-summarytable.png') }}"
                         className="img-responsive" alt="" title=""/>
                </div>
                <div className="item">
                    <img src="{{ static('images/products/home/screenshot-excel.png') }}"
                         className="img-responsive" alt="" title=""/>
                </div>
                <div className="item">
                    <img src="{{ static('images/products/home/screenshot-powerpoint.png') }}"
                         className="img-responsive" alt="" title=""/>
                </div>
 */