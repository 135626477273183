import React, { Component } from 'react'

import PrevNextNav from '../PrevNextNav'
import TestimonialCard from './TestimonialCard'

import './style.scss'

import { StaticQuery } from 'gatsby'
import { graphql } from 'gatsby'

// flickity implementation slightly convoluted to prevent prerender issues
export default class TestimonialSlider extends Component {
  constructor(props) {
    super(props)

    this.state = { Flickity: null }
    if (typeof window !== 'undefined') {
      const Flickity = require('react-flickity-component')
      this.state.Flickity = Flickity
    }
  }

  componentDidMount() {
    // force correct rendering after mount
    setTimeout(() => {
      if (this.flkty) this.flkty.resize()
    }, 500)
  }

  flcktyNext = () => {
    this.flkty.next()
  }

  flcktyPrevious = () => {
    this.flkty.previous()
  }

  render() {
    const { Flickity } = this.state
    const flickityOptions = {
      cellAlign: 'left',
      contain: true,
      groupCells: true,
      pageDots: false,
      prevNextButtons: false,
    }

    return (
      <StaticQuery
        query={graphql`
          query {
            testimonials: allMarkdownRemark(
              filter: { frontmatter: { type: { eq: "testimonial" } } }
              sort: { order: ASC, fields: frontmatter___priority }
            ) {
              edges {
                node {
                  id
                  html: excerpt(pruneLength: 140)
                  frontmatter {
                    name
                    description
                    image {
                      childImageSharp {
                        fixed(width: 60, height: 60) {
                          ...GatsbyImageSharpFixed_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={({ testimonials }) => {
          return (
            <div className=" c-testimonial-wrapper bg-light">
              <section className="container p-0 position-relative">
                <div className="py-3 pt-sm-5 pb-sm-3 py-md-5">
                  <div className="row align-center">
                    <div className="col-sm-8 offset-sm-4 px-4">
                      <div className="c-mini-title">Testimonials</div>
                      <h2 className="c-section-title">
                        Why our users think you should use DataStories
                      </h2>
                      <a href='https://www.capterra.com/reviews/182212/DataStories?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge'>
                        <img border='0' src='https://assets.capterra.com/badge/e3c96d8af569928ff7db9cfca1154ac0.png?v=2127400&p=182212' />
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-10 col-sm-12 pt-2 pt-sm-4 offset-sm-2 position-static">
                      {Flickity && (
                        <div>
                          <Flickity
                            flickityRef={c => (this.flkty = c)}
                            className={'c-card-carousel'} // default ''
                            elementType={'div'} // default 'div'
                            options={flickityOptions} // takes flickity options {}
                            disableImagesLoaded={false} // default false
                            reloadOnUpdate // default false
                          >
                            {testimonials.edges.map(({ node }) => (
                              <div className="col-sm-7 col" key={node.id}>
                                <TestimonialCard data={node} />
                              </div>
                            ))}
                          </Flickity>
                          <div className="c-card-carousel-nav offset-sm-5">
                            <PrevNextNav
                              nextHandler={this.flcktyNext}
                              prevHandler={this.flcktyPrevious}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          )
        }}
      />
    )
  }
}
